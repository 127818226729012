import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import { datadogRum } from '@datadog/browser-rum';

import App from './App';
import * as serviceWorker from './serviceWorker';

datadogRum.init({
  applicationId: 'ee577d3b-dc0b-40c6-8a6f-a7380eddaf71',
  clientToken: 'pub4b2d8b3c23c821799e554267aded1505',
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'datadoghq.eu',
  service: 'connect-frontend',
  env: 'main',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  defaultPrivacyLevel: 'allow',
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
